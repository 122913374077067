<template>
  <div>
    <titulo-disciplinas @cadastrar="openModal" />

    <filtro @filtrar="getDisciplinas" @limparFiltro="getDisciplinas" />

    <tabela
      :items="disciplinas"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <paginacao
      :total="total"
      v-model="paginacao"
      @input="getDisciplinas(filtro)"
    />

    <modal-disciplina
      :form="modais.disciplinaDados"
      :exibir="modais.disciplina"
      @atualiza-tabela="getDisciplinas"
      @fechar="closeModal('disciplina')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import DisciplinaCurriculo from '@/common/services/curriculo-escolar/disciplina.service';

// Components:
import Filtro from '@/views/academico/cej/estrutura/disciplinas/components/Filtro.vue';
import Tabela from './components/Tabela.vue';
import ModalDisciplina from './components/Modal.vue';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloDisciplinas from '@/views/academico/outras-instituicoes/disciplinas/components/TituloDisciplinas';

export default {
  components: {
    TituloDisciplinas,
    Filtro,
    Tabela,
    Paginacao,
    ModalDisciplina,
  },
  data() {
    return {
      disciplinas: [],
      modais: {
        disciplina: false,
        disciplinaDados: {
          descricao: '',
          disciplinas: [],
        },
      },
      filtro: '',
      total: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
    };
  },
  mounted() {
    this.getDisciplinas();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getDisciplinas(buscarPor) {
      this.filtro = buscarPor;
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.obterPorFiltro(this.filtro, this.paginacao)
        .then(({ data }) => {
          this.disciplinas = data.itens;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        'Você realmente quer deletar esta disciplina?'
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.excluir(item)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Disciplina deletada!');
          this.getDisciplinas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = this.encontrarDisciplina(key, item))
        : (this.modais[`${key}Dados`] = {
            descricao: '',
            disciplinas: [],
          });
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
    // FUNÇÕES AUXILIARES:
    encontrarDisciplina(key, item) {
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.obterPorId(item.id)
        .then(({ data }) => {
          this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(data));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
