<template>
  <div class="mb-4">
    <b-row>
      <b-col cols="12" md="6">
        <input-text
          ref="filtrarPor"
          v-model="filtrarPor"
          type="text"
          :label="$t('ACADEMICO.FILTRAR_POR')"
          :placeholder="$t('ACADEMICO.FILTRAR_POR_PLACEHOLDER')"
          :minLength="6"
          @enter="filtrar"
          required
        />
      </b-col>
      <b-col cols="12" md="auto">
        <b-button class="w-100 botao-acao-filtro" variant="primary" @click="filtrar">
          {{ $t('GERAL.FILTRAR') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button
          class="w-100 botao-acao-filtro mt-3"
          variant="secondary"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// HELPERS & UTILS

// COMPONENTS
import { InputText } from '@/components/inputs';
export default {
  components: {
    InputText,
  },
  data() {
    return {
      filtrarPor: '',
    };
  },
  mounted() {
    window.addEventListener('keydown', this.enter);
  },
  methods: {
    filtrar() {
      this.$emit('filtrar', this.filtrarPor);
    },
    limparFiltro() {
      this.filtrarPor = '';
      this.$emit('limparFiltro');
    },
    enter(event) {
      if (event.key === 'Enter') {
        this.filtrar();
      }
    },
  },
};
</script>
