<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    titulo="Disciplinas"
    :campos="$refs"
    id="modalDisciplina"
  >
    <div class="row">
      <div class="col-12">
        <input-text
          ref="descricao"
          v-model="form.descricao"
          label="Disciplina"
          placeholder="Ex: História"
          required
        />
      </div>
      <div class="col-12">
        <p>
          Adicione (se houver) as disciplinas CEJ que são correspondentes a
          disciplina criada:
        </p>
        <b-row>
          <b-col>
            <input-select-search
              v-model="disciplinaSelecionada"
              label="Selecione uma disciplina:"
              :options="opcoes.disciplinasFiltradas"
              placeholder="Digite ou procure"
            />
          </b-col>
          <b-col md="auto">
            <b-button class="w-100 botao-acao-filtro" variant="primary" @click="vincularDisciplinaCEJ">
              Adicionar disciplina
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="col-12 mt-3">
        <b-table
          :fields="fields"
          :items="form.disciplinas"
          :empty-text="$t('GERAL.NENHUM_REGISTRO')"
          head-variant="light"
          stacked="md"
          show-empty
          striped
          hover
        >
          <template #cell(acoes)="item">
            <b-dropdown variant="outline-default">
              <b-dropdown-item @click="desvincularDisciplinaCEJ(item.item)">
                {{ $t('GERAL.EXCLUIR') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import DisciplinaCurriculo from '@/common/services/curriculo-escolar/disciplina.service';
import DisciplinasService from '@/common/services/academico/disciplinas.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputText,
    InputSelectSearch,
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
      opcoes: {
        disciplinasTodos: [],
        disciplinasFiltradas: [],
      },
      disciplinaSelecionada: '',
    };
  },
  mounted() {
    this.getDisciplinas();
  },
  watch: {
    form: {
      handler() {
        this.filtrarOpcoesIncluidas();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getDisciplinas() {
      this.$store.dispatch(START_LOADING);
      DisciplinasService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: { id: row.id },
          }));
          this.opcoes.disciplinasTodos = data;
          this.filtrarOpcoesIncluidas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    reobterDados() {
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.obterPorId(this.form.id)
        .then(({ data }) => {
          this.form.disciplinas = data.disciplinas;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    vincularDisciplinaCEJ() {
      this.form.id
        ? this.adicionarDisciplinaEquivalenteNoEditar()
        : this.adicionarDisciplinaEquivalenteNoCriar();
    },

    adicionarDisciplinaEquivalenteNoCriar() {
      if (this.disciplinaSelecionada) {
        this.form.disciplinas.push(this.disciplinaSelecionada);
      }
    },

    desvincularDisciplinaCEJ(item) {
      this.form.id
        ? this.removerDisciplinaEquivalenteNoEditar(item)
        : this.form.disciplinasId.splice(item.index, 1);
    },
    // FUNÇÕES CRUD:
    adicionarDisciplinaEquivalenteNoEditar() {
      // quando for editar
      let novaDisciplina = {
        disciplinaCurriculoId: this.form.id,
        disciplinaInternaId: this.disciplinaSelecionada.id,
      };
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.vincular(novaDisciplina)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.reobterDados();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    removerDisciplinaEquivalenteNoEditar(item) {
      let disciplina = {
        disciplinaCurriculoId: this.form.id,
        disciplinaInternaId: item.id,
      };
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.desvincular(disciplina)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.reobterDados();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    salvar() {
      if (!this.validarFormulario()) return;
      !this.form.id ? this.criar() : this.editar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      const newForm = {
        descricao: this.form.descricao,
        disciplinasId: this.form.disciplinas.map((i) => i.id),
      };
      DisciplinaCurriculo.criar(newForm)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.disciplinaSelecionada = '';
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      DisciplinaCurriculo.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Disciplina editada!');
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },

    // funçoes aux
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    filtrarOpcoesIncluidas() {
      if (
        this.opcoes.disciplinasTodos?.length === 0 ||
        this.form.disciplinas?.length === 0
      ) {
        this.opcoes.disciplinasFiltradas = this.opcoes.disciplinasTodos;
      } else {
        this.opcoes.disciplinasFiltradas = this.opcoes.disciplinasTodos.filter(
          (disciplina) => {
            return !this.form.disciplinas?.some(
              (item) => item.id === disciplina.value.id
            );
          }
        );
      }
    },
  },
};
</script>
